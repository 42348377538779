// React
import React, { useRef, useEffect, useState } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';
import { useInView } from 'react-intersection-observer';
import Hammer from 'react-hammerjs';

// Utilities
import { scrollTop, scrollTo, disableScroll } from 'utilities';
import { useTheme, useWindowSize } from 'hooks';

// Components
import Hero from 'components/_landing/hero/hero';
import Arrow from 'components/arrow';
import PromotionalProduct from './components/promotionalProduct';

const PromotionalProductsComponent = props => {
    const { pageContext, content, location, parentSlugs, theme } = props;

    // Get active products
    const promotionalProducts = props.content.promotionalProducts.filter(
        product => product.active
    );

    // Define ref to use as scrollAnchor
    const promotionalProductsAnchor = useRef(null);

    // Move time
    const moveTime = {
        wheel: 1500,
        swipe: 900,
        click: 900,
    };

    // Html style
    const [, setHtmlStyle] = useGlobal('htmlStyle');

    // Header show
    const [, setForceHeaderShow] = useGlobal('forceHeaderShow');

    // State
    const [moving, setMoving] = useState(false);
    const [promoScrollEnabled, setPromoScrollEnabled] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);

    // Window Size
    const windowSize = useWindowSize();

    // Enable scrolling mode for promotional products
    const enterScrollingMode = () => {
        if (!promoScrollEnabled) {
            // Disable page scroll
            disableScroll.on();
            setHtmlStyle('overflow: hidden');

            // Force header
            setForceHeaderShow(true);
            setTimeout(() => {
                setForceHeaderShow(false);
            }, 100);

            // Enable after a short period of time
            setTimeout(() => {
                setHtmlStyle('');
                disableScroll.off();

                // Scroll to anchor
                scrollTo(
                    scrollTop(promotionalProductsAnchor.current),
                    () => {
                        // Disable scroll again
                        setHtmlStyle('overflow: hidden');
                        disableScroll.on();

                        // Enable hammer and scrolling
                        setTimeout(() => {
                            setHammerEnabled(true);
                            setPromoScrollEnabled(true);
                        }, 200);
                    },
                    200
                );
            }, 1000);
        }
    };

    // Disable scrolling mode for promotional products
    const leaveScrollingMode = () => {
        // Enable page scroll
        disableScroll.off();

        // Disable hammer
        setHammerEnabled(false);

        // Disable promo scroll
        setPromoScrollEnabled(false);
        setHtmlStyle('');
        setMoving(false);
    };

    // Init on load
    useEffect(() => {
        disableScroll.on();
        setHtmlStyle('overflow: hidden');
    }, []);

    // Wheel events
    const onWheel = event => {
        if (event.deltaY < 0) {
            previous();
        } else {
            next();
        }
    };

    // Swipe event
    const [hammerEnabled, setHammerEnabled] = useState(true);
    const onSwipe = event => {
        const { deltaY } = event;
        if (hammerEnabled) {
            if (deltaY > 0) {
                previous('swipe');
            } else if (deltaY < 0) {
                next('swipe');
            }
        }
    };

    // Go to previous promotional product
    const previous = (control = 'wheel') => {
        // Only scoll if moving
        if (promoScrollEnabled && !moving) {
            // Set moving
            setMoving(true);

            // Move to previous or leave promo scrolling mode
            currentIndex === 0 ? () => {} : setCurrentIndex(currentIndex - 1);

            // Undo set moving after moveTime timeout
            setTimeout(() => {
                setMoving(false);
            }, moveTime[control]);
        }
    };

    // Go to next promotional product
    const next = (control = 'wheel') => {
        // Only scoll if moving
        if (promoScrollEnabled && !moving) {
            // Set moving
            setMoving(true);

            // Move to next or leave promo scrolling mode
            currentIndex === promotionalProducts.length
                ? leaveScrollingMode()
                : setCurrentIndex(currentIndex + 1);

            // Unlock if swipe event after all slides
            if (
                control === 'swipe' &&
                currentIndex - 1 === promotionalProducts.length - 1
            ) {
                leaveScrollingMode();

                // Scroll a bit
                scrollTo(
                    windowSize.height,
                    () => {
                        setCurrentIndex(promotionalProducts.length);
                    },
                    400
                );
            }

            // Undo set moving after moveTime timeout
            setTimeout(() => {
                setMoving(false);
            }, moveTime[control]);
        }
    };

    // Navigate with arrows
    const navigate = direction => {
        // Initiate scrolling in page scroller
        if (!promoScrollEnabled) {
            enterScrollingMode(true);
        }

        switch (direction) {
            case 'up':
                previous('click');
                break;
            case 'down':
                next('click');
                break;
        }
    };

    // Set up intersection observer
    const [promotionalProductsAnchorTop, promotionalProductsAnchorTopInView] =
        useInView();

    // Set up intersection observer
    const [
        promotionalProductsAnchorBottom,
        promotionalProductsAnchorBottomInView,
    ] = useInView();

    // Enable scrolling mode if intersections are visible
    useEffect(() => {
        if (promotionalProductsAnchorTopInView) {
            enterScrollingMode(true);
        }
    }, [promotionalProductsAnchorTopInView]);

    // Update stuff based on currentIndex
    const [, setHeaderTheme] = useGlobal('headerTheme');
    const [currentProductTheme, setCurrentProductTheme] = useState(
        s.color('black')
    );

    // Set theme
    const defaultTheme = useTheme(theme);

    useEffect(() => {
        // Theme
        if (
            promotionalProductsAnchorBottomInView &&
            currentIndex > 0 &&
            promotionalProducts.length >= currentIndex
        ) {
            const productTheme = s.themeColor(
                promotionalProducts[currentIndex - 1].theme
            );
            setCurrentProductTheme(productTheme);
            setHeaderTheme(productTheme);
        } else {
            setCurrentProductTheme(defaultTheme);
            if (
                content?.selectedCover?.landingPageHeaderTextColor === 'white'
            ) {
                setHeaderTheme(s.color('black'));
            } else if (
                content?.selectedCover?.landingPageHeaderTextColor === 'black'
            ) {
                setHeaderTheme(s.color('white'));
            } else {
                setHeaderTheme(defaultTheme);
            }
        }

        setForceHeaderShow(true);
    }, [
        currentIndex,
        promoScrollEnabled,
        promotionalProductsAnchorBottomInView,
    ]);

    return (
        <>
            <div>
                <PromotionalProducts onWheel={onWheel}>
                    <Anchor ref={promotionalProductsAnchor} />
                    <AnchorTop ref={promotionalProductsAnchorTop} />
                    <Hammer
                        onPan={onSwipe}
                        {...{
                            direction: 'DIRECTION_VERTICAL',
                            threshold: 3,
                            velocity: 0.1,
                            options: { enable: true },
                        }}>
                        <div>
                            <Hero
                                {...{
                                    pageContext,
                                    parentSlugs,
                                    content: content.selectedCover,
                                    location,
                                    index: 0,
                                    currentIndex,
                                    ticker: content.ticker,
                                }}
                            />
                            {promotionalProducts.map((product, index) => (
                                <PromotionalProduct
                                    key={product.id}
                                    {...{
                                        leaveScrollingMode,
                                        product,
                                        index: index + 1,
                                        currentIndex,
                                        pageContext,
                                        location,
                                    }}
                                />
                            ))}
                        </div>
                    </Hammer>
                    <Navigate>
                        <Up {...{ currentIndex }}>
                            <Arrow
                                {...{
                                    theme: currentProductTheme,
                                    direction: 'up',
                                    hover: true,
                                    onClick: () => navigate('up'),
                                }}
                            />
                        </Up>
                        <State
                            {...{ theme: currentProductTheme, currentIndex }}>
                            {Math.max(currentIndex, 1)} /{' '}
                            {promotionalProducts.length}
                        </State>
                        <Down
                            {...{
                                currentIndex,
                                max: promotionalProducts.length,
                            }}>
                            <Arrow
                                {...{
                                    theme: currentProductTheme,
                                    hover: true,
                                    onClick: () => navigate('down'),
                                }}
                            />
                        </Down>
                    </Navigate>
                    <AnchorBottom ref={promotionalProductsAnchorBottom} />
                </PromotionalProducts>
            </div>
        </>
    );
};

const PromotionalProducts = styled.div`
    display: block;
    color: ${s.color('black').default()};
    height: 100vh;
    position: relative;
    margin-left: -5.8vw;
    margin-right: -5.8vw;
    z-index: ${s.layout.zIndex.promotionalProducts};
    overflow: hidden;
`;

const Anchor = styled.div`
    height: 1px;
    width: 100%;
    background: transparent;
    top: 0;
    position: absolute;
`;

const AnchorTop = styled.div`
    height: 2px;
    width: 100%;
    top: 20px;
    position: absolute;
    background: transparent;
`;

const AnchorBottom = styled.div`
    height: 2px;
    width: 100%;
    bottom: 50px;
    background: transparent;
    position: absolute;
`;

const Navigate = styled.div`
    ${s.grid.contentWidth()};
    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};

    ${s.responsive.property('display', {
        0: 'none',
        20: 'flex',
    })};

    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
    align-items: flex-end;
    pointer-events: none;
`;

const Up = styled.div`
    pointer-events: auto;
    transition: opacity 400ms;
    ${props =>
        props.currentIndex === 0 &&
        css`
            opacity: 0;
            pointer-events: none;
        `}
`;

const Down = styled.div`
    pointer-events: auto;
    transition: opacity 400ms;
    ${props =>
        (props.currentIndex === props.max || props.currentIndex === 0) &&
        css`
            opacity: 0;
            pointer-events: none;
        `}
`;

const State = styled.div`
    margin: 1em 0;
    ${s.fonts.style('h17')}
    color: ${props => props.theme.text()};

    transition: opacity 400ms;
    ${props =>
        props.currentIndex === 0 &&
        css`
            opacity: 0;
            pointer-events: none;
        `}
`;

export default PromotionalProductsComponent;
